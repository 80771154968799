import React, { useContext } from "react";
import { HomeContext } from "@/context/home.context";
import CommentStormCard from "./CommentStormCard";

const CommentStorm = () => {
  const { listStormComment } = useContext(HomeContext);

  return (
    <div>
      {listStormComment?.results && listStormComment?.results?.length ? (
        <>
          <div className="uppercase text-grey-700 text-fs-14 font-semibold mb-1 py-2">
            bão bình luận 🔥🔥🔥🔥
          </div>
          <div className="flex flex-col gap-3">
            {listStormComment?.results.map((item, index) => (
              <CommentStormCard key={item?._id} reviewContent={item} />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CommentStorm;
