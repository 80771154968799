import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { useAuth } from "@/hooks/useAuth";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import SeenProjectCard from "./SeenProjectCard";

const SeenProjects = () => {
  const [data, setData] = useState([]);
  const { userDataHeader } = useAuth();
  const getData = useCallback(async () => {
    if (userDataHeader)
      try {
        const res = await axios.request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/user/topviewed",
          headers: {
            ...userDataHeader,
          },
        });
        setData(res.data);
      } catch (error) {}
  }, [userDataHeader]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {data && data?.length ? (
        <div>
          <div className="uppercase text-grey-700 text-fs-14 font-semibold mb-3">
            🏢 dự án đã xem
          </div>
          <div className="w-full flex md:flex-col  gap-3 md:bg-white rounded-lg md:max-h-[726px] overflow-auto pt-2 ">
            {data.map((item, index) => (
              <SeenProjectCard key={item?._id} item={item} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SeenProjects;
