import { HomeContext } from "@/context/home.context";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import HeaderHome from "./HeaderHome";
import Newest from "./Newest";
import { getListFollowProject } from "@/api/endpoint/reviews/get-list-follow-project";
import { useAuth } from "@/hooks/useAuth";
import HeaderListSearchProject from "@/modules/Desktop/ListSearchProject/HeaderListSearch";

const HOME_TABS = [
  {
    label: "Mới nhất",
    key: "lastest",
    icon: <i className="mrv mrv-timelapse text-fs-20"></i>,
    component: <Newest />,
  },
  {
    label: "Top review",
    key: "topreview",
    icon: <i className="mrv mrv-trending_up text-fs-20"></i>,
    component: <Newest />,
  },
  {
    label: "Hot",
    key: "hot",
    icon: <i className="mrv mrv-local_fire_department text-fs-20"></i>,
    component: <Newest />,
  },
];

const HomeComponent = () => {
  const { activeTab, setActiveTab, setListFollowProject, handleFetchData } =
    useContext(HomeContext);
  const { token } = useAuth();
  const handleSelectTab = (tab: string) => {
    handleFetchData(tab);
    setActiveTab(tab);
  };
  const [isShowScrolHeader, setIsShowScrollHeader] = useState(false);

  const handleGetListFollowProject = async () => {
    const res = await getListFollowProject(token);
    if (res) {
      setListFollowProject(res);
    }
  };
  useEffect(() => {
    if (token) {
      handleGetListFollowProject();
    }
  }, [token]);

  const handleScroll = () => {
    const position = window.scrollY;
    setIsShowScrollHeader(position > 276);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="wrap-home bg-grey-100 min-h-[calc(100vh-77px)]">
      <HeaderListSearchProject
        showSearch
        isShowScrolHeader={isShowScrolHeader}
      />
      <HeaderHome isShowScrolHeader={isShowScrolHeader} />
      <div className="layout pb-6 pt-[3.5rem]">
        <div className="home-tabs">
          {HOME_TABS.map((tab) => (
            <div
              className={clsx(
                "home-tab",
                activeTab === tab.key ? "home-tab-active" : ""
              )}
              key={tab.key}
              onClick={() => handleSelectTab(tab.key)}>
              {tab.icon} {tab.label}
            </div>
          ))}
        </div>
        <div className="home-tab-content ">
          {HOME_TABS.find((tab) => tab.key === activeTab)?.component}
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
