import React, { useContext } from "react";
import { HomeContext } from "@/context/home.context";
import AvatarWithName from "@/components/AvatarWithName";
import PopOver from "@/components/PopOver";
import { STATIC_DOMAIN } from "@/constants/environment";

const TopMembers = () => {
  const { listTopMember } = useContext(HomeContext);
  return (
    <div>
      {listTopMember && listTopMember?.length ? (
        <>
          <div className="uppercase text-grey-700 text-fs-14 font-semibold mb-1 py-2">
            Thành viên tích cực nhất ⭐⭐️⭐️️
          </div>
          <div className="py-4 px-3 w-full bg-white rounded-lg shadow hover:shadow-xl duration-300 flex flex-col gap-4">
            {listTopMember.map((item, index) => (
              <div className="flex items-center gap-2" key={item?._id}>
                <div className="relative">
                  <AvatarWithName
                    imageClass="!w-10 !h-10"
                    imageProps={{ resizeOnFly: false }}
                    className="flex-0 border border-solid border-black-50 rounded-full"
                    avatarOnly
                    name={item?.name}
                    src={item?.avatar ? item.avatar : null}
                  />
                  <img
                    src={STATIC_DOMAIN + `/images/top${index + 1}.svg`}
                    alt="top-icon"
                    className="absolute top-6 right-0"
                  />
                </div>
                <div className="flex flex-col">
                  {item?.name?.length > 25 ? (
                    <PopOver
                      trigger="hover"
                      dropdownClassName="mt-2 !p-0 rounded !bg-transparent"
                      content={
                        <div className="py-1 px-2 bg-black-800 text-fs-14 text-white max-w-[30rem] break-word">
                          {item?.name}
                        </div>
                      }
                      position="top-left">
                      <div className="text-grey-800 text-fs-16 font-medium line-clamp-1 break-all w-full">
                        {item?.name?.substring(0, 25) + "..."}
                      </div>
                    </PopOver>
                  ) : (
                    <div className="text-grey-800 text-fs-16 font-medium line-clamp-1 break-all w-full">
                      {item?.name}
                    </div>
                  )}

                  <div className="text-grey-600 text-fs-14">
                    {item?.total} bình luận
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TopMembers;
