import React, { useEffect, useState, useContext } from "react";
import UserTime from "@/components/UserTime";
import ModalRaw from "@/components/Modal";
import { CommentTypeEnum } from "@/constants/enum/comment";
import { Form, Input, notification } from "antd";
import Button from "@/components/Button";
import InputReplyComponent from "./InputReplyComponent";
import { useAuth } from "@/hooks/useAuth";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import axios from "axios";
import { fileType } from "@/constants/file";
import dynamic from "next/dynamic";
import { getListFollowProject } from "@/api/endpoint/reviews/get-list-follow-project";
import { HomeContext } from "@/context/home.context";
import ReviewShowTextContent from "@/components/ReviewShowTextContent";
import { MediaTypeEnum } from "@/constants/enum/media";
import { urlRegex } from "@/constants/common-constant";

const FollowDetailFileList = dynamic(import("./FollowDetailFileList"), {
  ssr: false,
});

const ModalReplyFollowProject = ({
  open,
  onClose,
  reviewContent,
  userAction,
  urlUserAvatar,
}: any) => {
  const { setListFollowProject } = useContext(HomeContext);
  const [loading, setLoading] = useState(false);

  const { user, userDataHeader, token } = useAuth();
  const [form] = Form.useForm();
  useEffect(() => {
    if (!open) form.resetFields();
  }, [form, open]);

  useEffect(() => {
    form.setFieldsValue({
      nickName: user?.name,
      comment: `@{{${reviewContent?.topreview?.createdBy?._id}||${
        reviewContent?.topreview?.nickName || "anymos"
      }}} `,
    });
  }, [form, open, user]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <Form
          form={form}
          initialValues={{
            comment: null,
            nickName: user?.name,
          }}
          labelAlign="left"
          onFinish={async (value) => {
            setLoading(true);
            try {
              const mediaFile =
                value?.file && value?.file?.length
                  ? value?.file?.map((item) => {
                      if (item.name.match(fileType.file)) {
                        return {
                          type: MediaTypeEnum.FILE,
                          s3Keys: [item.s3Key],
                        };
                      }
                      if (item.name.match(fileType.image)) {
                        return {
                          type: MediaTypeEnum.IMAGE,
                          s3Keys: [item.s3Key],
                        };
                      }
                      if (item.name.match(fileType.video)) {
                        return {
                          type: MediaTypeEnum.VIDEO,
                          s3Keys: [item.s3Key],
                        };
                      }
                    })
                  : [];
              const res = await axios.request({
                method: "post",
                baseURL: MEEY_REVIEW_API_URL,
                headers: { ...userDataHeader },
                url: "v1/comments/" + reviewContent?.topreview?._id + "/reply",
                data: {
                  comment: value.comment?.trim(),
                  nickName: `${value.nickName ?? ""}`,
                  links: value.comment.match(urlRegex) || [],
                  mentions: [
                    {
                      userId: reviewContent?.topreview?.createdBy?._id,
                      nickName: reviewContent?.topreview?.nickName || "",
                    },
                  ],
                  media: mediaFile,
                },
              });
              if (res) {
                const resFollow = await getListFollowProject(token);
                setListFollowProject(resFollow);
                onClose();
              }
            } catch (error) {
              if (error?.response?.data?.code === 400) {
                form.setFields([
                  {
                    name: "comment",
                    errors: [error?.response?.data?.message],
                  },
                ]);
              } else
                notification.error({
                  message: "Có lỗi xảy ra, vui lòng thử lại sau",
                  style: {
                    width: "100%",
                    top: 56,
                  },
                });
            } finally {
              setLoading(false);
            }
          }}>
          <ModalRaw
            contentClassName="w-[40.5rem]"
            onClose={() => {
              form.resetFields();
              onClose();
            }}
            headerClassName="!bg-grey-100"
            header={
              <div className="text-grey-800 text-fs-20 font-semibold">
                {reviewContent?.topreview?.type == CommentTypeEnum.QUESTION
                  ? "Trả lời"
                  : "Phản hồi"}
              </div>
            }
            footer={
              <div className="flex w-full justify-end gap-4">
                <Button
                  color="grey"
                  className="!px-8"
                  onClick={() => {
                    form.resetFields();
                    onClose();
                  }}>
                  Hủy
                </Button>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldsError, isFieldTouched, getFieldValue }) => {
                    return (
                      <Button
                        btnType="filled"
                        type="button"
                        onClick={form.submit}
                        className="!px-9 "
                        disabled={
                          loading ||
                          !getFieldValue(["comment"])?.trim() ||
                          getFieldValue(["comment"])?.trim() ===
                            `@${
                              reviewContent?.topreview?.nickName || "Ẩn danh"
                            }` ||
                          getFieldsError().some(({ errors }) => errors.length)
                        }>
                        {reviewContent?.topreview?.type ==
                        CommentTypeEnum.QUESTION
                          ? "Gửi trả lời"
                          : "Gửi phản hồi"}
                      </Button>
                    );
                  }}
                </Form.Item>
              </div>
            }
            open={open}>
            <div className="max-h-[70vh] overflow-auto">
              <div className="p-3 border border-solid border-black-50 bg-grey-100 rounded-lg mb-4">
                <UserTime
                  src={urlUserAvatar}
                  name={reviewContent?.topreview?.nickName || "Ẩn danh"}
                  className="w-fit"
                  textClass="text-fs-14 text-grey-600"
                  size={32}
                  time={reviewContent?.topreview?.publishedAt}
                  haveAction={true}
                  actionTitle={userAction.title}
                  actionIcon={
                    reviewContent?.topreview?.type ==
                    CommentTypeEnum.QUESTION ? (
                      <i className="mrv mrv-contact_support text-fs-20 leading-5 text-purple-500"></i>
                    ) : (
                      <i className="mrv mrv-review text-fs-20 leading-5 text-blue-500"></i>
                    )
                  }
                  aboutProject={
                    <>
                      <span className="text-fs-14 text-grey-600">về </span>
                      <span className="text-fs-14 text-grey-800 font-medium ">
                        {reviewContent?.title?.length > 45
                          ? reviewContent?.title.substring(0, 45) + "..."
                          : reviewContent?.title}
                      </span>
                    </>
                  }
                />
                <div className="text-fs-14 text-grey-700 max-h-[139px] overflow-y-auto mt-2">
                  <ReviewShowTextContent
                    content={reviewContent?.topreview?.comment}
                  />
                </div>
                <FollowDetailFileList
                  data={reviewContent?.topreview}
                  maxFileShow={6}
                />
              </div>
              <Form.Item
                label="Nickname cho bạn "
                name="nickName"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input
                  maxLength={50}
                  size="large"
                  placeholder="Đặt bất kì nickname nào bạn thích"
                  disabled={loading}
                />
              </Form.Item>
              {form.getFieldValue("comment") ? (
                <InputReplyComponent
                  setLoading={setLoading}
                  loading={loading}
                  label={CommentTypeEnum.QUESTION ? "Câu trả lời" : "Phản hồi"}
                  placeholder={
                    CommentTypeEnum.QUESTION
                      ? "Viết nội dung trả lời"
                      : "Viết nội dung phản hồi"
                  }
                  requiredMessage={
                    CommentTypeEnum.QUESTION
                      ? "Vui lòng nhập nội dung trả lời"
                      : "Vui lòng nhập nội dung phản hồi"
                  }
                  data={reviewContent?.topreview}
                />
              ) : null}
            </div>
          </ModalRaw>{" "}
        </Form>
      </div>
    </>
  );
};
export default ModalReplyFollowProject;
