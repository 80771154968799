import React, { useContext } from "react";
import { HomeContext } from "@/context/home.context";
import FollowProjectCard from "./FollowProjectCard";
import ModalViewDetailFileFollow from "./ModalDetailFileFollow";
const ListFollowProject = () => {
  const {
    listFollowProject
  } = useContext(HomeContext);
  return (
    <div>
      {listFollowProject?.results && listFollowProject?.results?.length ? (
        <>
          <div className="uppercase text-grey-700 text-fs-14 font-semibold mb-1 py-2">
            👂 dự án đang hóng
          </div>
          <div className="flex flex-col gap-3">
            {listFollowProject?.results.map((item, index) => (
              <FollowProjectCard
                key={item?._id}
                reviewContent={item}
              />
            ))}
          </div>
          <ModalViewDetailFileFollow/>
        </>
      ) : null}
    </div>
  );
};

export default ListFollowProject;
