import { saveSearchHistory } from "@/api/endpoint/search/save-search-history";
import Button from "@/components/Button";
import { useAuth } from "@/hooks/useAuth";
import { useRouter } from "next/router";
import { useState } from "react";
import LocationSelect from "./LocationSelect";
import SearchProject from "./SearchProject";

/* eslint-disable @next/next/no-img-element */
const HomeBannerSearchBox = () => {
  const [valueSearch, setValueSearch] = useState({
    text: "",
    location: {
      city: [],
      district: [],
      ward: [],
    },
  });
  const { userDataHeader } = useAuth();
  const { push } = useRouter();
  const handleSaveSearchHistory = async (project?: any) => {
    try {
      await saveSearchHistory(
        {
          keywords: project
            ? project?.title || project?.keywords
            : valueSearch?.text?.trim(),
          project: project?._id,
        },
        userDataHeader
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" flex items-center bg-white rounded-lg w-fit relative">
      <LocationSelect
        onChange={(value) => {
          setValueSearch({ ...valueSearch, location: value });
        }}
      />
      <SearchProject
        onChangeText={(value) => {
          setValueSearch({
            ...valueSearch,
            text: value,
          });
        }}
        valueSearch={valueSearch}
        onPressEnter={() => {
          push({
            pathname: "/search",
            query: {
              title: valueSearch.text,
              city: valueSearch.location.city?.map((e) => e._id),
              district: valueSearch.location.district?.map((e) => e._id),
              ward: valueSearch.location.ward?.map((e) => e._id),
            },
          });
        }}
        className="w-[20rem]"
      />
      {/* <NewSearchProject /> */}
      <Button
        className="px-4 py-2.5 h-9 mr-1 text-base font-medium "
        color="primary"
        btnType="filled"
        onClick={() => {
          if (valueSearch.text) {
            handleSaveSearchHistory();
            // handleSearch(valueInput);
          }
          push({
            pathname: "/search",
            query: {
              title: valueSearch.text,
              city: valueSearch.location.city?.map((e) => e._id),
              district: valueSearch.location.district?.map((e) => e._id),
              ward: valueSearch.location.ward?.map((e) => e._id),
            },
          });
        }}>
        Tìm kiếm
      </Button>
    </div>
  );
};

export default HomeBannerSearchBox;
