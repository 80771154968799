import Button from "@/components/Button";
import MeeyImage from "@/components/MeeyImage";
import PopupAvatarUser from "@/components/PopupAvatarUser";
import { STATIC_DOMAIN } from "@/constants/environment";
import { useAuth } from "@/hooks/useAuth";
import { useEffect, useState } from "react";
import HomeBannerSearchBox from "./HomeBannerSearchBox";
import ListScrollHotReview from "./ListScrollHotReview";
import dynamic from "next/dynamic";
const ModalAddReview = dynamic(import("./ModalAddReview"), {
  ssr: false,
});
import PopupNotification from "@/components/PopupNotification";

/* eslint-disable @next/next/no-img-element */
const HeaderHome = ({ isShowScrolHeader }: { isShowScrolHeader?: boolean }) => {
  const { user, login } = useAuth();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      {
        <div className="bg-header h-64">
          <div className="flex justify-between pt-4 px-[60px]">
            <div
              className="cursor-pointer"
              onClick={() => {
                window?.location?.reload();
              }}>
              <MeeyImage
                src={STATIC_DOMAIN + "/logo.svg"}
                alt="home"
                resizeOnFly={false}
                resizeOptions={{
                  width: 131,
                  height: 42,
                }}
              />
            </div>
            <div className="flex items-center gap-5">
              <ModalAddReview buttonClassName="!h-9" />
              {!user ? (
                <Button
                  color="white"
                  className="!h-9 ml-5"
                  onClick={login}
                  prefixIcon={
                    <i className="mrv mrv-account_circle text-fs-18"> </i>
                  }>
                  Đăng nhập
                </Button>
              ) : (
                <>
                  <PopupNotification isShowScrolHeader={isShowScrolHeader} />
                  <PopupAvatarUser />
                </>
              )}
            </div>
          </div>
          <div className="ml-44 mt-7 w-fit">
            <h1 className="text-white text-5xl font-semibold mb-3">
              Review dự án
            </h1>
            <div className="text-base mb-5 text-white">
              Cập nhật liên tục thông tin bên lề hấp dẫn của 5000 dự án trên
              toàn quốc
            </div>
            {mounted ? <HomeBannerSearchBox /> : null}
          </div>
          <ListScrollHotReview />
        </div>
      }
    </>
  );
};

export default HeaderHome;
