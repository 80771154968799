import { StormComment } from "Models";
import MeeyRate from "@/components/MeeyRate";
import Link from "next/link";
import { DEFAULT_IMAGE_CARD } from "@/constants/image";
import UserTime from "@/components/UserTime";
import ListFileReview from "@/modules/common/ListFileReview";
import ReviewShowTextContent from "@/components/ReviewShowTextContent";
interface IProps {
  reviewContent?: StormComment;
}

const CommentStormCard = ({ reviewContent }: IProps) => {
  return (
    <div className="p-3 w-full bg-white rounded-lg cursor-pointer shadow hover:shadow-xl duration-300 flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        {reviewContent?.review?.voteScore ? (
          <div className="flex items-center gap-1 h-6">
            <div className="text-fs-14 text-grey-700">
              {reviewContent?.review?.voteScore?.toFixed(1)}
            </div>
            <MeeyRate
              disable={true}
              value={reviewContent?.review?.voteScore}
              itemClassName="!text-fs-24"
            />
          </div>
        ) : null}

        <Link
          href={`/project/${reviewContent?.slug}-${
            reviewContent?.shortId ? reviewContent?.shortId : reviewContent?._id
          }`}
          title={reviewContent?._id}
          target="_blank"
          className="text-fs-14 font-semibold">
          {reviewContent?.title}
        </Link>
        <div className="text-fs-14 text-grey-700 break-word leading-6">
          “
          <ReviewShowTextContent
            content={reviewContent?.review?.comment}
            limit={200}
          />
          {reviewContent?.review?.comment?.length > 200 ? " ..." : null}”
        </div>
        <Link
          href={`/review/${reviewContent?.review?._id}`}
          target="_blank"
          title={reviewContent?.review?._id ?? ""}>
          <ListFileReview data={reviewContent?.review} maxFileShow={4} />
        </Link>

        <div className=" mt-2.5 items-center gap-4">
          <UserTime
            src={
              reviewContent?.review?.createdBy?.avatar
                ? reviewContent.review?.createdBy.avatar
                : reviewContent?.review?.nickName
                ? null
                : DEFAULT_IMAGE_CARD
            }
            name={reviewContent?.review?.nickName || "Ẩn danh"}
            className="w-fit"
            textClass="text-fs-14 text-grey-600 line-clamp-1"
            size={32}
            time={reviewContent?.review?.publishedAt}
            haveAction={true}
            maxLengthName={32}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentStormCard;
