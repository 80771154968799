import { emojis } from "@/constants/emoji";
import { HomeContext } from "@/context/home.context";
import { getRelativeTime } from "@/utils/datetime";
import Link from "next/link";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
let isDown = false;
let startX;
let scrollLeft;
let scrolldelay;
let scrollToLeft = false;

const getRandomEmoji = (cat?: any, sub?: any) => {
  const random = (array) => array[~~(Math.random() * array.length)];

  if (!emojis[cat]) cat = random(Object.keys(emojis));

  if (!emojis[cat][sub]) sub = random(Object.keys(emojis[cat]));

  return random(emojis[cat][sub]);
};

const ListScrollHotReview = () => {
  const { listTopAnimation } = useContext(HomeContext);
  const scrollEl = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);

  const pageScroll = useCallback(() => {
    if (
      scrollEl.current &&
      scrollEl.current.scrollWidth > scrollEl.current.clientWidth
    ) {
      if (scrollToLeft) {
        scrollEl.current.scrollLeft = scrollEl.current.scrollLeft - 1;
      } else scrollEl.current.scrollLeft = scrollEl.current.scrollLeft + 1;

      if (
        scrollEl.current.scrollLeft + scrollEl.current.offsetWidth + 2 >
        scrollEl.current.scrollWidth
      ) {
        scrollToLeft = true;
        // scrollEl.current.scrollLeft = scrollEl.current.scrollLeft - 1;
      }
      if (scrollEl.current.scrollLeft < 2) {
        scrollToLeft = false;

        // scrollEl.current.scrollLeft = scrollEl.current.scrollLeft + 1;
      }

      scrolldelay = setTimeout(pageScroll, 10);
    }
  }, []);

  useEffect(() => {
    setMounted(true);
    pageScroll();
    return () => {
      clearTimeout(scrolldelay);
    };
  }, [pageScroll]);
  return listTopAnimation?.results?.length ? (
    <div
      className="flex overflow-hidden w-full gap-3 mt-7 cursor-pointe select-none cursor-pointer"
      ref={scrollEl}
      draggable={"false"}
      onMouseDown={(e) => {
        isDown = true;
        startX = e.pageX - scrollEl.current.offsetLeft;
        scrollLeft = scrollEl.current.scrollLeft;
      }}
      onMouseOver={() => {
        clearTimeout(scrolldelay);
      }}
      onMouseOut={() => {
        pageScroll();
      }}
      onMouseLeave={(e) => {
        isDown = false;
      }}
      onMouseUp={(e) => {
        e.preventDefault();
        isDown = false;
      }}
      onMouseMove={(e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - scrollEl.current.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        scrollEl.current.scrollLeft = scrollLeft - walk;
      }}>
      {listTopAnimation?.results?.map((item) => (
        <Link
          href={`/review/${item._id}`}
          draggable={"false"}
          className="py-1 px-3 whitespace-nowrap text-fs-12 bg-blue-600 text-white rounded-lg hover:text-grey-100 self-center align-middle leading-[18px]"
          key={item._id}>
          {mounted ? [1, 2, 3].map((e) => getRandomEmoji()).join("") : null}{" "}
          {item.nickName ? item.nickName : "Ẩn danh"} vừa{" "}
          {item?.label?.toLocaleLowerCase()} {item.project?.title}{" "}
          {mounted ? [1, 2, 3].map((e) => getRandomEmoji()).join("") : null} !!!{" "}
          {mounted ? getRelativeTime(item?.publishedAt) : null}
        </Link>
      ))}
    </div>
  ) : null;
};

export default ListScrollHotReview;
