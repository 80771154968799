import MeeyImage from "@/components/MeeyImage";

import PopOver from "@/components/PopOver";
import ReviewShowTextContent from "@/components/ReviewShowTextContent";
import UserTime from "@/components/UserTime";
import { FluctuationScoreEnum } from "@/constants/common-constant";
import { MEDIA_URL, STATIC_DOMAIN } from "@/constants/environment";
import { DEFAULT_USER_AVATAR } from "@/constants/image";
import { DEFAULT_LOGO_PROJECT } from "@/constants/project-constant";
import { convertAddressNoStreet, resizeOnFlyUrl } from "@/utils";
import { ProjectReview } from "Models";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";

import dynamic from "next/dynamic";
import { fileType } from "@/constants/file";
const ListFileReview = dynamic(import("@/modules/common/ListFileReview"), {
  ssr: false,
});

const HomeProjectCard = ({
  data,
  loading,
}: {
  data: ProjectReview;
  loading?: boolean;
}) => {
  const urlUserAvatar = useMemo(() => {
    if (data?.latestComment?.createdBy?.avatar) {
      return data?.latestComment?.createdBy?.avatar.includes(MEDIA_URL) &&
        data?.latestComment?.createdBy?.avatar.match(
          fileType.resizeImageSupport
        )
        ? resizeOnFlyUrl({
            uri: data?.latestComment?.createdBy?.avatar.replace(MEDIA_URL, ""),
            width: 32,
            height: 32,
            gravity: "ce",
            resizingType: "auto",
          })
        : data?.latestComment?.createdBy?.avatar;
    } else if (data?.latestComment?.nickName) {
      return null;
    } else return `${STATIC_DOMAIN}/${DEFAULT_USER_AVATAR}`;
  }, [data]);

  const [isShowMore, setIsShowMore] = useState(false);
  useEffect(() => {
    const reviewEle = document.getElementById(`review-${data?._id}`);
    if (!reviewEle) setIsShowMore(false);
    setIsShowMore(reviewEle?.scrollHeight > reviewEle?.offsetHeight);
  }, [data]);

  return (
    <Link
      href={`/project/${data.slug}-${data.shortId ? data.shortId : data._id}`}>
      <div className="flex gap-4 pt-2 pr-4 pb-3 pl-2 w-full bg-white rounded-lg cursor-pointer shadow hover:shadow-xl duration-300 shrink-0">
        <div className="w-[3.75rem] h-[3.75rem] relative shrink-0">
          <MeeyImage
            src={
              data.logo
                ? data.logo.uri
                : `${STATIC_DOMAIN}/${DEFAULT_LOGO_PROJECT}`
            }
            alt={data.title}
            resizeOnFly={!!data?.logo?.uri}
            resizeOptions={{
              width: 0,
              height: 60,
            }}
            className="w-full h-full absolute object-contain rounded border border-solid border-grey-100"
          />
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-center w-full border-b border-dashed border-grey-300 pb-3 gap-3">
            <div className="flex-0">
              <div className="text-fs-18 text-grey-700 font-semibold flex items-center gap-3">
                <PopOver
                  trigger="hover"
                  dropdownClassName="mt-2 !p-0 rounded !bg-transparent"
                  content={
                    <div className="py-1 px-2 bg-black-800 text-fs-14 text-white max-w-[30rem] break-word">
                      {data?.title}
                    </div>
                  }
                  position="top-left">
                  <span className="text-grey-800 text-fs-18 font-semibold line-clamp-1 break-words">
                    {data?.title}
                  </span>
                </PopOver>
                <div className="flex items-center gap-1 shrink-0">
                  {data?.voteScore ? (
                    <>
                      {data?.fluctuationScore == FluctuationScoreEnum.DOWN ? (
                        <i className="mrv mrv-trending_down text-red-600 text-fs-20"></i>
                      ) : null}
                      {data?.fluctuationScore == FluctuationScoreEnum.UP ? (
                        <i className="mrv mrv-trending_up text-green-500 text-fs-20"></i>
                      ) : null}
                      <span className="text-fs-14 text-grey-700 font-normal">
                        {Number.isInteger(data?.voteScore)
                          ? data?.voteScore
                          : data?.voteScore?.toFixed(1)}
                      </span>
                      <i className="mrv mrv-star text-[#FFC633] text-fs-20"></i>
                    </>
                  ) : null}
                  {data?.totalHotReview ? (
                    <span className="text-fs-14 text-grey-700 font-normal">
                      ({data?.totalHotReview} reviews)
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="text-fs-14 text-grey-500 mt-0.5">
                {convertAddressNoStreet(data)}
              </div>
            </div>
            {data?.totalView ? (
              <div className="text-grey-500 text-fs-14 flex items-center gap-1 shrink-0">
                <span>{data?.totalView}</span>
                <i className="mrv mrv-visibility text-grey-400 text-fs-20"></i>
              </div>
            ) : null}
          </div>
          {data?.latestComment ? (
            <div className="mt-2">
              {data?.latestComment?.comment ? (
                <div
                  className="text-fs-14 text-grey-700 line-clamp-2 break-word  w-full relative leading-6"
                  id={`review-${data?._id}`}>
                  “
                  <ReviewShowTextContent
                    content={data?.latestComment?.comment}
                  />
                  ”
                  {isShowMore ? (
                    <span className="absolute top-5 right-0">”</span>
                  ) : null}
                </div>
              ) : null}
              <ListFileReview data={data?.latestComment} />
              <div className="flex mt-1.5 items-center gap-4">
                <UserTime
                  src={urlUserAvatar}
                  name={data?.latestComment?.nickName || "Ẩn danh"}
                  className="w-fit"
                  textClass="text-fs-14 text-grey-600"
                  size={24}
                  time={data?.latestComment?.publishedAt}
                  maxLengthName={50}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

export default HomeProjectCard;
