import { useAnimateState } from "@/hooks/useAnimateState";
import {
  lockScroll,
  unlockScroll,
} from "@/vendor/scroll-locker/useScrollLocker";
import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useState } from "react";

export type ModalPositionType =
  | "left-top"
  | "top-right"
  | "bottom-left"
  | "bottom-right";
export interface ModalProps {
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  bodyClassName?: string;
  header?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  position?: ModalPositionType;
  trigger?: "click" | "hover";
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onReset?: () => void;
}

const Modal = ({
  className,
  header,
  headerClassName,
  contentClassName,
  footerClassName,
  bodyClassName,
  footer,
  open,
  children,
  onClose,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { trigger, triggerClose } = useAnimateState({
    open: isOpen,
    handleClose: () => {
      onClose ? onClose() : setIsOpen(false);
    },
  });

  const handleClose = useCallback(
    (e) => {
      if (
        (e.type === "keydown" && e.key === "Escape") ||
        e.type === "click" ||
        e.type === "mousedown"
      ) {
        triggerClose();
      }
    },
    [triggerClose]
  );

  useEffect(() => {
    if (isOpen) {
      lockScroll();
      window.addEventListener("keydown", handleClose);
    }
    return () => {
      unlockScroll();
      window.removeEventListener("keydown", handleClose);
    };
  }, [isOpen, handleClose]);

  return isOpen ? (
    <div
      className={clsx(
        "wrap-modal duration-300",
        className,
        trigger ? "opacity-100" : "opacity-0"
      )}
      onMouseDown={(e) => {
        handleClose(e);
      }}>
      {
        <div
          className={clsx("wrap-modal-content", contentClassName)}
          onMouseDown={(e) => {
            // var target = getSelectedElementFromPoint(e.clientX, e.clientY);
            e.stopPropagation();
            return;
          }}
          onClick={(e) => e.stopPropagation()}>
          {header ? (
            <div className={clsx("modal-header", headerClassName)}>
              <div>{header}</div>

              <div
                onClick={handleClose}
                className="cursor-pointer text-fs-24 text-grey-800">
                <i className="mrv mrv-close"></i>
              </div>
            </div>
          ) : null}
          <div className={clsx("modal-body", bodyClassName)}>{children}</div>
          {footer ? (
            <div className={clsx("modal-footer", footerClassName)}>
              {footer}
            </div>
          ) : null}
        </div>
      }
    </div>
  ) : null;
};

export default Modal;
