import { useAuth } from "@/hooks/useAuth";
import SeenProjects from "@/modules/common/Home/SeenProjects";
import CommentStorm from "./CommentStorm";
import ListFollowProject from "./ListFollowProject";
import TopMembers from "./TopMembers";

const BoxInfo = () => {
  const { token } = useAuth();

  return (
    <div className="flex-[0_0_19.5rem] overflow-auto flex flex-col gap-6">
      {token ? <ListFollowProject /> : null}
      <SeenProjects />
      <TopMembers />
      <CommentStorm />
    </div>
  );
};

export default BoxInfo;
