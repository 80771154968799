import { getListReviews } from "@/api/endpoint/reviews/get-list";
import { PAGE_SIZE_REVIEWS } from "@/constants/number";
import { HomeContext } from "@/context/home.context";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import HomeProjectCard from "../HomeProjectCard";
import BoxInfo from "./BoxInfo";

const Newest = () => {
  const { listReviews, activeTab, currentPage, setCurrentPage } =
    useContext(HomeContext);
  const [projectReviews, setProjectReviews] = useState(
    listReviews?.results ?? []
  );

  useEffect(() => {
    setProjectReviews([]);
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    if (listReviews?.results && listReviews?.results?.length) {
      if (listReviews?.page == 1) {
        setProjectReviews([...listReviews?.results]);
      } else {
        setProjectReviews([...projectReviews, ...listReviews?.results]);
      }
    }
  }, [listReviews?.results]);

  const getListProjectReview = async () => {
    const res = await getListReviews(activeTab, {
      page: currentPage + 1,
      limit: PAGE_SIZE_REVIEWS,
    });
    setCurrentPage(currentPage + 1);
    if (res) {
      setProjectReviews([...projectReviews, ...res?.results]);
    }
  };

  const onLoadMore = () => {
    getListProjectReview();
  };

  return (
    <div className=" mt-5 flex gap-6">
      <div className="pb-4 overflow-x-hidden overflow-y-auto w-full">
        <InfiniteScroll
          dataLength={projectReviews?.length || 0}
          next={onLoadMore}
          hasMore={projectReviews?.length < listReviews?.totalResults}
          loader={
            <div className="px-4 flex items-center justify-center">
              <div className="dot-flashing" />
            </div>
          }>
          <div className="flex-1 gap-y-3 flex flex-col">
            {projectReviews &&
              projectReviews.map((item) => (
                <HomeProjectCard data={item} key={item._id} />
              ))}
          </div>
        </InfiniteScroll>
      </div>
      <BoxInfo />
    </div>
  );
};

export default Newest;
